.ifram {
  height: 100vh!important;
  width: 100vw!important;
  border: none;
  scrollbar-width: 0;
  }
  .box{
    overflow: hidden;
    border: none;
    scrollbar-width: 0;
    height: 100vh!important;
    width: 100vw!important;
  }
